<template>
	<div class="headerWrap">
		<div class="header flex align-center justify-between">
			<div class="flex align-center">
				<img :src="logo" style="width: 145px;height: 42px;">
				<div @mouseenter="numUpRoll" class="ft15" style="margin-left: 76px;cursor:pointer;" @click="goHome">首页
				</div>
				<div @mouseenter="StopRoll(item,index)" @mouseleave="UpRoll(item,index)"
					:class="[selectIndex==index?'headerLists':'headerList','ft15','color666']"
					v-for="(item,index) in list" :key='index'>
					{{item.name}}
				</div>
				<div @click="goAboutUs" @mouseenter="numUpRoll" class="ft15 color666"
					style="margin-left: 40px;cursor:pointer;">关于我们</div>
			</div>
			<div class="flex align-center">
				<img :src="lanauge" style="width: 20px;height: 20px;margin-right: 8px;">
				<div class="color000 ft15" style="font-weight: bold;">CN</div>
			</div>
		</div>
		<div @mouseleave="numUpRoll" class="headerBottom" v-show="numIndex!=null">
			<div v-show="numIndex!=1">
				<div class="secondMenuWrap flex flexWrap">
					<div @click="StopSecondRoll(item,index)" v-for="(item,index) in classifyList" :key="index"
						class="secondMenu ft16 color000">{{item.title}}</div>
				</div>
			</div>
			<div v-show="numIndex==1">
				<div class="secondMenuWrap flex flexWrap mlvw">
					<div class="menuBox ft16 color000" v-for="(item,index) in classifyList" :key="index">
						<div>{{item.childName}}</div>
						<div :class="[item.child.length>5?'flex':'','flexWrap']">
							<div @click="StopSecondRoll(items,indexs)" style="margin-top: 20px;" class="color666 ft16 childTitle" v-for="(items,indexs) in item.child">
								{{items.title}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from '@vue/reactivity'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		ref,
		toRefs,
		onMounted,
	} from 'vue'
	export default {
		name: 'header',
		props: {
			msg: String
		},
		setup() {
			// 使用router跳转的方法
			const router = useRouter()
			// 获取router传参
			const route = useRoute()

			const data = reactive({
				list: [{
						name: '数字技术服务',
						arr: [{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykf.png',
								title: '数字应用开发',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykfBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfn.png',
								title: '技术赋能',
								content: '随着互联网在全球范围内的扩展，中国互联网快速发展，',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfnBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjg.png',
								title: 'IT基础架构',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjgBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyg.png',
								title: '数字员工RPA',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szygBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjs.png',
								title: '云计算',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjsBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyy.png',
								title: '大数据应用',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyyBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznyy.png',
								title: '人工智能应用',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qkl.png',
								title: '区块链',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qklBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaq.png',
								title: '风控与安全',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaqBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtyw.png',
								title: '系统运维',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtywBg.png',
							},
						]
					},
					{
						name: '咨询与解决方案',
						arr: [{
								childName: '咨询服务',
								child: [{
										image: '',
										title: '数字化转型咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字业务咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字产品咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字创新创业咨询',
										content: '',
										bg: ''
									},
								]
							},
							{
								childName: '解决方案',
								child: [{
										image: '',
										title: '企业数字化基础套装',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字业务产品敏捷演进',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '业务与数据中台',
										content: '',
										bg: ''
									},
								]
							},
							{
								childName: '行业应用',
								child: [{
										image: '',
										title: '客户关系管理',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字渠道',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字营销',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字供应链',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '多模式电商',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字社群',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字零售',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字政务',
										content: '',
										bg: ''
									}
								]
							}
						]
					},
					{
						name: '数字化运营',
						arr: [{
								childName: '',
								child: [],
								image: '',
								title: '数字内容服务',
								content: '',
								bg: ''
							},
							{
								childName: '',
								child: [],
								image: '',
								title: '业务流程服务',
								content: '',
								bg: ''
							},
							{
								childName: '',
								child: [],
								image: '',
								title: '客服服务',
								content: '',
								bg: ''
							}
						]
					}
				], //头部导航
				logo: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/logo.png', //头部logo
				lanauge: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/lanauge.png', //右上角语言图标
				selectIndex: null,
				numIndex: null, //滑动的是哪个内容
				classifyList: [], //下方的二级菜单
			})
			// 鼠标悬浮
			const StopRoll = (item, index) => {
				data.selectIndex = index
				data.numIndex = index
				data.classifyList = data.list[data.numIndex].arr
			};
			// 鼠标离开
			const UpRoll = (item, index) => {
				data.selectIndex = null
			};
			// 隐藏二级菜单
			const numUpRoll = () => {
				data.numIndex = null
				data.classList = []
			};
			onMounted(() => {
				console.log(data.list)
			});
			// 跳转首页
			const goHome = () => {
				// 当前滚动条直接到顶部
				document.documentElement.scrollTop = 0;
				if (route.path == '/') {
					// 刷新当前页面
					location.reload();
				} else {
					router.replace({
						path: '/'
					})
				}
			};
			// 二级菜单跳转
			const StopSecondRoll = (item, index) => {
				console.log(item)
				// router.replace({
				// 	name: 'numberTechnology',
				// 	query: {
				// 		// query: common.$encode(JSON.stringify(item))   如果需要加密就用这个
				// 		query: JSON.stringify(item)
				// 	}
				// })
			};
			// 跳转关于我们
			const goAboutUs = () => {
				// 当前滚动条直接到顶部
				document.documentElement.scrollTop = 0;
				if (route.path == '/aboutUs') {
					// 刷新当前页面
					location.reload();
				} else {
					router.replace({
						path: '/aboutUs'
					})
				}
			};
			return {
				...toRefs(data),
				StopRoll,
				UpRoll,
				numUpRoll,
				goHome,
				goAboutUs,
				StopSecondRoll,
			}
		},
	}
</script>


<style scoped>
	.headerWrap {
		position: relative;
	}

	.header {
		width: 100%;
		height: 73px;
		background: #fff;
		padding: 0 1.9vw 0 5vw;
		box-sizing: border-box;
		border-bottom: 1px solid #EDEDED;
	}

	.headerList {
		margin-left: 40px;
		cursor: pointer;
	}

	.headerLists {
		margin-left: 40px;
		position: relative;
		cursor: pointer;
	}

	.headerLists::after {
		content: '';
		width: 28px;
		height: 3px;
		background: #bd00ff;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		bottom: -135%;
	}

	.headerBottom {
		background: #ffffff;
		/* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.40); */
		position: absolute;
		width: 100%;
		background: #fff;
		top: 100%;
		padding: 54px 0 97px 0;
		box-sizing: border-box;
	}

	.secondMenuWrap {
		width: 100%;
		margin: 0 auto;
		padding: 0 2.6vw;
		box-sizing: border-box;
	}
	
	.mlvw{
		margin-left: 13.5vw;
	}

	.secondMenu {
		width: 160px;
		margin-left: 13.5vw;
		text-align: left;
		margin-top: 30px;
	}
	
	.menuBox{
		width: 390px;
		margin-top: 30px;
	}

	.secondMenu:hover {
		color: #BD00FF;
		cursor: pointer;
	}
	
	.childTitle{
		width: 160px;
	}
	
	.childTitle:hover {
		color: #BD00FF;
		cursor: pointer;
	}
</style>
