<template>
	<div>
		<!-- 头部 -->
		<div class="sticky">
			<Header></Header>
		</div>
		<!-- 轮播图 -->
		<homeBanner></homeBanner>
		<!-- 数智探索 -->
		<div class="numeracy">
			<div style="text-align: center;">
				<div class="numeracyTitle">数智探索</div>
				<div class="numeracyTitles">智慧科技 助力新经济</div>
			</div>
			<div class="flex align-center justify-center" style="margin-top: 92px;">
				<div class="numeracyBox" v-for="(item,index) in numeracyList" :key='index'>
					<div style="width: 100%;height: 298px;overflow: hidden;border-radius: 8px;">
						<img :src="item.image" style="width: 100%;height: 298px;">
					</div>
					<div class="color000 ft18 bold contentOne" style="">{{item.title}}</div>
					<div class="contentTwo" style="height: 57px;">{{item.content}}</div>
					<div class="flex align-center justify-between" style="margin-top: 20px;">
						<div class="flex align-center">
							<!-- <img :src="time" style="width: 15px;height: 15px;">
							<div style="color: #595F6F;font-size: 12px;margin-left: 7px;">{{item.date}}</div> -->
						</div>
						<div class="flex align-center">
							<div style="color: #595F6F;font-size: 12px;margin-right: 6px;">更多</div>
							<img :src="allImage" style="width: 7px;height: 10px;">
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 数字技术服务 -->
		<div class="math">
			<img :src="mathBg" class="mathBg">
			<div class="mathCon">
				<div class="colorfff" style="font-size: 48px;">数字技术服务</div>
				<div class="colorfff" style="font-size: 16px;margin-top: 18px;">
					新技术、新服务密集推出，新模式、新业态竞相涌现，是我国服务业提质扩容、转型升级的有力见证，构<br>成了“十三五”期间经济社会发展的一大亮点 数字技术推动服
				</div>
				<div class="mathBtm flex align-center flexWrap">
					<div v-for="(item,index) in mathList" class="mathBox">
						<img :src="item.image" class="iconStyle">
						<div style="font-size: 18px;margin-top: 15px;" class="color000 bold">{{item.title}}</div>
						<div class="twoHidden" style="color: #666666;font-size: 14px;margin-top: 16px;">{{item.content}}
						</div>
						<div class="btn" @click="goDetail(item,index)">了解更多</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 咨询与解决方案 -->
		<div class="help">
			<div class="bold color000 helpTitle">咨询与解决方案</div>
			<div class="helpContent flex justify-between">
				<div>
					<div @click="selectLeft(item,index)" :class="selectIndex==index?'selectHelpLeft':'helpLeft'"
						v-for="(item,index) in consultList" :key='index'>
						{{item.title}}
					</div>
				</div>
				<div class="helpCenter">
					<div class="ft16 color000 bold">{{articleTitle}}</div>
					<div class="flex flexWrap align-center" style="margin-top: 33px;">
						<div class="listWrap" v-for="(item,index) in centerList" :key='index'>
							<div class="ft18 color000 bold oneHidden" style="margin-top: 3vw;">
								{{item.title}}
							</div>
							<div style="height: 5.7vw;display: flex;align-items: center;flex-flow: column;justify-content: space-between;">
								<div class="ft14 color666 twoHidden" style="margin-top: 16px;height: 38px;">{{item.content}}</div>
								<img @mouseenter="StopRoll(item,index)" @mouseleave="UpRoll(item,index)"
									:src="selectCenterIndex==index?selectCenterImg:centerImg"
									style="width: 27px;height: 27px;cursor: pointer;">
							</div>
						</div>
					</div>
				</div>
				<div class="helpRight">
					<img :src="rightImage" style="width: 100%;height: 100%;">
				</div>
			</div>
		</div>
		<!-- 数字化运营 -->
		<div class="Digitizing">
			<div class="DigitizingTitle">数字化运营</div>
			<div class="DigitizingContent flex align-center justify-center">
				<div class="sznrWrapBox">
					<div class="sznrBox">
						<img :src="nub" style="width: 42px;height: 47px;margin-top: 5.5vw;">
						<div class="colorfff bold" style="font-size: 30px;margin-top: 14px;">数字内容管理</div>
						<div class="colorfff ft16" style="margin-top: 30px;">
							为您提供高效的服务保障、多样化的服务支持计划，以及支撑上云前、中、后全流程的专业技术服务，让您云上业务更便捷、更安心</div>
						<div class="knowBtn">了解更多</div>
					</div>
				</div>
				<div class="businessWrapBox">
					<div class="businessBox">
						<img :src="ywlcfw" style="width: 41px;height: 42px;margin-top: 14.4vw;">
						<div class="colorfff bold" style="font-size: 30px;margin-top: 14px;">
							业务流程服务
						</div>
					</div>
				</div>
				<div class="customerWrapBox">
					<div class="customerBox">
						<img :src="kffw" style="width: 41px;height: 42px;margin-top: 14.4vw;">
						<div class="colorfff bold" style="font-size: 30px;margin-top: 14px;">
							客服服务
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<div style="margin-top: 131px;">
			<Footer></Footer>
		</div>
	</div>
</template>

<script>
	import Header from '../components/header.vue'
	import Footer from '../components/footer.vue'
	import homeBanner from '../components/homeBanner.vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	import {
		reactive
	} from '@vue/reactivity'
	import {
		ref,
		toRefs,
		getCurrentInstance,
		onMounted
	} from 'vue'
	export default {
		name: 'Home',
		components: {
			Header,
			Footer,
			homeBanner
		},
		setup(props, ctx) {
			// 公共方法
			const common = getCurrentInstance().appContext.config.globalProperties
			// 使用router跳转的方法
			const router = useRouter()
			// 获取router传参
			const route = useRoute()
			onMounted(() => {
				data.centerList = data.consultList[0].arr
				data.articleTitle = data.consultList[0].articleTitle
			});
			// 数据
			const data = reactive({
				bannerList: ['https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner.jpg',
					'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner1.jpg',
					'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner2.jpg',
				],
				swiperIndex: 0,
				numeracyList: [{
					image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/imageOne.png',
					title: '深入JDK中的Optional',
					content: `Optional最早是Google公司Guava中的概念，代表的是可选值。Optional类从Java8版本开始加入豪华套餐，主要为了解决程序中的NPE问题
					，从而使得更少的显式判空，防止代码污染，另一方面，也使得领域模型中所隐藏的知识，得以显式体现在代码中。`,
					date: '2021-05-26',
				},{
					image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/imageTwo.png',
					title: '在线数据迁移，数字化时代的必修课 京东云数据迁移实践',
					content: `打破数据边界，是数字化时代常挂在嘴边的一句话，数据的价值是在流动中体现的，数据应用也是如此。以往为了满足开发、
					测试、数据保护容灾和数据分析的需要，我们不断对数据进行复制、备份、迁移，因此数据迁移非常重要。`,
					date: '2021-05-26',
				}, {
					image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/imageThree.png',
					title: '深入JDK中的Optional',
					content: `Optional更像是一个容器，其中存放的成员变量是一个T类型的value，可值可Null，使用的是Wrapper模式，对value操作进行了包装与设计。`,
					date: '2021-05-26',
				}],
				time: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/time.png', //日期图标
				allImage: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/all.png', //更多图标
				mathBg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/mathBg.png', //数字服务背景图
				mathList: [{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykf.png',
						title: '数字应用开发',
						content: '多平台软件定制、开发，定制化资讯，落地更快速。',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykfBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfn.png',
						title: '技术赋能',
						content: '激发经济社会生机活力。即利用信息技术，实现企业技术',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfnBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjg.png',
						title: 'IT基础架构',
						content: '专为您的企业混合云和 AI 战略设计的服务器、存储产品',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjgBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyg.png',
						title: '数字员工RPA',
						content: '智能自动化服务具有巨大的潜力，可助力改善客户体验。',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szygBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjs.png',
						title: '云计算',
						content: '充分发挥云的高效率、敏捷性和成本优势，助力企业加速',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjsBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyy.png',
						title: '大数据应用',
						content: '强大的分析、精准的数据，专业团队高效、稳定提供数据',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyyBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznyy.png',
						title: '人工智能应用',
						content: '数据洞见真知，智能驱动价值，智能时代',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qkl.png',
						title: '区块链',
						content: '全球瞩目的区块链存储技术，经过高度加密，数据安全',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qklBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaq.png',
						title: '风控与安全',
						content: '洞悉业务异常流量，构建精准防御全面保障业务增长',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaqBg.png',
					},
					{
						image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtyw.png',
						title: '系统运维',
						content: '以数据为基础、以算法为支撑、以场景为导向，应用先进',
						bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtywBg.png',
					},
				],
				selectIndex: 0,
				consultList: [{
						title: '咨询服务',
						articleTitle:'为您提供高效的服务保障、多样化的服务支持计划，以及支撑上云前、中、后全流程的专业技术服务，让您云上业务更便捷、更安心',
						arr: [{
								title: '数字化转型咨询',
								content: '深入企业业务痛点,构建前瞻性业务模型,从企业战略流',
							},
							{
								title: '数字业务咨询',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
							},
							{
								title: '数字产品咨询',
								content: '解锁新时代新方程，将智慧巧思、实践经验与前沿科技整',
							},
							{
								title: '数字创新创业咨询',
								content: '实现企业转型、服务客户、信息化技术沉淀、云计算大数',
							}
						]
					}, {
						title: '解决方案',
						articleTitle:'为您提供高效的服务保障、多样化的服务支持计划，以及支撑上云前、中、后全流程的专业技术服务，让您云上业务更便捷、更安心',
						arr: [{
								title: '企业数字化基础套装',
								content: '提供企业级专属套装方案，全面、高效一步到位',
							},
							{
								title: '数字业务产品敏捷演进',
								content: '帮助企业打破数据孤岛,整合分析数据,驱动经营决策,',
							},
							{
								title: '业务与数据中台',
								content: '提供流程驱动业务中台和数据中台双中台管理，实现商品管理',
							}
						]
					},
					{
						title: '行业应用',
						articleTitle:'为您提供高效的服务保障、多样化的服务支持计划，以及支撑上云前、中、后全流程的专业技术服务，让您云上业务更便捷、更安心',
						arr: [{
								title: '客户关系管理',
								content: '助力企业智能化、数据化的销售管理变革，深度赋能销售',
							},
							{
								title: '数字渠道',
								content: '通过网络和数字的形式对内容进行有效的传播，提高不同',
							},
							{
								title: '数字营销',
								content: '帮助企业整合客户数据资产、洞察客户行为、实现千人千面',
							},
							{
								title: '数字供应链',
								content: '敏捷战略和智能工作流程，助力企业构建数字化供应链',
							}
						]
					}
				],
				articleTitle:'',//文章标题
				rightImage: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rightImage.png', //右侧图片
				centerList: [], //中间的列表
				selectCenterImg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/PurpleAll.png',
				centerImg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/greyAll.png',
				selectCenterIndex: null, //鼠标移动到的图片
				nub: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/nub.png', //数字内容管理icon图标
				ywlcfw: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/ywlcfw.png', //业务流程icon图标
				kffw: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/kffw.png', //客服icon图标
			})
			// 轮播图位置
			const swiperChange = (e) => {
				data.swiperIndex = e
			};
			// 选择咨询与解决方案的三个盒子
			const selectLeft = (item, index) => {
				data.centerList = item.arr
				data.selectIndex = index
				data.articleTitle = item.articleTitle
			};
			// 滑入选择咨询与解决方案的图标变色
			const StopRoll = (item, index) => {
				data.selectCenterIndex = index
			};
			// 滑出变回原图标
			const UpRoll = (item, index) => {
				data.selectCenterIndex = null
			};
			// 跳转数字技术服务详情
			const goDetail = (item, index) => {
				// router.push({
				// 	name: 'numberTechnology',
				// 	query: {
				// 		// query: common.$encode(JSON.stringify(item))   如果需要加密就用这个
				// 		query: JSON.stringify(item)
				// 	}
				// })
			};
			return {
				...toRefs(data),
				swiperChange,
				selectLeft,
				StopRoll,
				UpRoll,
				goDetail,
			}
		}
	}
</script>
<style type="text/css" scoped>
	.numeracy {
		width: 100%;
		height: 900px;
		padding-top: 90px;
		box-sizing: border-box;
	}

	.numeracyBox {
		width: 360px;
		height: 510px;
		background: #ffffff;
		border-radius: 5px;
		box-shadow: 19.8px 19.8px 60px 28px rgba(0, 0, 0, 0.10);
		margin-left: 30px;
		padding: 15px 15px 0 15px;
		box-sizing: border-box;
		cursor: pointer;
		overflow: hidden;
	}

	.numeracyBox img:hover {
		animation: scaleDraw 1s linear forwards;
	}

	@keyframes scaleDraw {

		/*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
		from {
			transform: scale(1);
			/*开始为原始大小*/
		}

		to {
			transform: scale(1.2);
			/*放大1.1倍*/
		}
	}

	.numeracyTitle {
		font-size: 48px;
		color: #000000;
		font-weight: bold;
	}

	.numeracyTitles {
		font-size: 20px;
		color: #B1B1B1;
		letter-spacing: 8px;
		margin-top: 12px;
	}

	.contentOne {
		margin-top: 35px;
		overflow: hidden;
		/*超出部分隐藏*/
		text-overflow: ellipsis;
		/* 超出部分显示省略号 */
		white-space: nowrap;
		/*规定段落中的文本不进行换行 */
	}

	.contentTwo {
		color: #B1B1B1;
		font-size: 14px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		margin-top: 8px;
	}

	.math {
		width: 100%;
		height: 818px;
		position: relative;
	}

	.mathBg {
		width: 100%;
		height: 818px;
		position: absolute;
		top: 0;
		z-index: -1;
	}

	.mathCon {
		padding: 5.9vw 12vw 0 12vw;
		box-sizing: border-box;
	}

	.mathBtm {
		width: 100%;
		height: 600px;
		background: #fff;
		margin-top: 70px;
	}

	.mathBox {
		width: 19.9%;
		height: 299.5px;
		border-top: 1px solid #EDEDED;
		border-right: 1px solid #EDEDED;
		transition: all 0.5s ease-in;
		text-align: center;
		padding: 2.1vw 2.5vw 0 1.9vw;
		box-sizing: border-box;
	}

	.mathBox:hover {
		box-shadow: -28px 0px 60px 28px rgba(2, 8, 22, 0.20);
	}

	.mathBox:nth-child(5) {
		border-right: 0;
		width: 20%;
	}

	.mathBox:nth-child(10) {
		border-right: 0;
		width: 20%;
	}

	.iconStyle {
		width: 51px;
		height: 58px;
	}

	.btn {
		width: 100px;
		height: 40px;
		border: 2px solid #b2b2b2;
		border-radius: 22px;
		color: #000000;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
		margin: 0 auto;
		margin-top: 30px;
	}

	.btn:hover {
		color: #fff;
		background: #BD00FF;
		border: 1px solid #BD00FF;
		cursor: pointer;
	}

	.help {
		width: 100%;
		/* height: 1040px; */
		background: #f9f9f9;
		padding: 225px 12.5vw 0 12.5vw;
		box-sizing: border-box;
	}

	.helpTitle {
		font-size: 48px;
		text-align: center;
	}

	.helpContent {
		width: 100%;
		/* height: 675px; */
		height: 35.1vw;
		margin-top: 77px;
	}

	.helpLeft {
		width: 24.2vw;
		/* width: 466px; */
		height: 11.7vw;
		text-align: center;
		font-size: 24px;
		line-height: 11.7vw;
		border-top: 1px solid #EFEFEF;
		color: #B1B1B1;
		border-left: 6px solid #f9f9f9;
		cursor: pointer;
	}

	.selectHelpLeft {
		width: 24.2vw;
		/* width: 466px; */
		/* height: 225px; */
		height: 11.7vw;
		text-align: center;
		font-size: 24px;
		line-height: 11.7vw;
		color: #000000;
		background: #fff;
		border-left: 6px solid #BD00FF;
		cursor: pointer;
	}

	.helpCenter {
		background: #fff;
		padding: 2.7vw 3vw;
		box-sizing: border-box;
		width: 32.6vw;
		/* width: 626px; */
	}

	.helpRight {
		width: 17.8vw;
		/* width: 343px; */
		/* height: 675px; */
		height: 35.1vw;
	}

	.listWrap {
		width: 12.6vw;
		height: 12.6vw;
		/* width: 242px; */
		/* height: 242px; */
		border-right: 1px solid #EDEDED;
		text-align: center;
		border-top: 1px solid #EDEDED;
		padding: 0 20px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.listWrap:nth-child(1) {
		border-top: none;
	}

	.listWrap:nth-child(2) {
		border-right: none;
		border-top: none;
	}

	.listWrap:nth-child(4) {
		border-right: none;
	}

	.DigitizingTitle {
		font-size: 48px;
		font-weight: bold;
		color: #000000;
		margin-top: 115px;
		text-align: center;
	}

	.DigitizingContent {
		margin-top: 107px;
	}
	
	.sznrWrapBox{
		width: 708px;
		height: 455px;
		overflow: hidden;
	}

	.sznrBox {
		width: 708px;
		height: 455px;
		background-image: url(https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/sznrBg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 0 5vw 0 5.2vw;
		box-sizing: border-box;
		/* padding: 0 5vw 0 5.2vw; */
		/* box-sizing: border-box; */
	}

	.sznrBox:hover {
		animation: scaleDraw 1s linear forwards;
	}

	.knowBtn {
		width: 100px;
		height: 40px;
		background: #bd00ff;
		border-radius: 20px;
		color: #fff;
		font-size: 14px;
		text-align: center;
		line-height: 40px;
		margin-top: 34px;
	}
	
	.businessWrapBox{
		width: 344px;
		height: 455px;
		margin-left: 20px;
		overflow: hidden;
	}

	.businessBox {
		width: 344px;
		height: 455px;
		/* margin-left: 20px; */
		background-image: url(https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/ywlcfwBg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 0 57px;
		box-sizing: border-box;
	}

	.businessBox:hover {
		animation: scaleDraw 1s linear forwards;
	}
	
	.customerWrapBox{
		width: 344px;
		height: 455px;
		margin-left: 20px;
		overflow: hidden;
	}

	.customerBox {
		width: 344px;
		height: 455px;
		/* margin-left: 20px; */
		background-image: url(https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/kffwBg.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		padding: 0 57px;
		box-sizing: border-box;
	}

	.customerBox:hover {
		animation: scaleDraw 1s linear forwards;
	}
</style>
