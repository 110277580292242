<template>
	<div>
		<!-- 科技赋能共享进步 -->
		<div class="progress">
			<div class="colorfff bold progressTitle">
				科技赋能 共享进步
			</div>
			<div class="progressBtn">
				立即咨询
			</div>
		</div>
		<div class="wrap">
			<div class="wrapContainer flex justify-between">
				<div v-for="(item,index) in list" :key='index'>
					<div class="ft14" style="color: #333;">{{item.name}}</div>
					<div :style="index==1?'width:430px':''"
						:class="[item.arr.length>5 || index==1?'flex':'','childWrap','flexWrap']">
						<div :class="[item.arr.length>5?'ml35':'']" v-for="(items,indexs) in item.arr" :key='indexs'>
							<div class="childName ft14" @click="goDetail(items,indexs)">
								{{items.title}}
							</div>
							<div v-if="index==1">
								<div>
									<div :style="indexs!=0?'margin-left:43px':''" class="ft14" style="color: #333;">
										{{items.childName}}</div>
									<div @click="goDetail(itemss,indexss)" :style="indexs!=0?'margin-left:43px':''" class="ft14 itemssTitle" style=""
										v-for="(itemss,indexss) in items.child" :key="indexss">
										{{itemss.title}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div class="ft14" style="color: #333;">联系我们</div>
					<div class="ft14" style="color: #666;margin-top: 10px;">
						电话：400-056-5060
					</div>
					<div class="ft14" style="margin-top: 10px;">
						邮箱：<span style="color: #0066CC;">service@kootec.com</span>
					</div>
				</div>
			</div>
			<div class="bottom flex align-center justify-between">
				<div>
					Copyright © 2015 The Pear. All rights reserved.<a href="https://beian.miit.gov.cn">京ICP备14015322号-2</a>
				</div>
				<img :src="logo" style="width: 94px;height: 27px;">
			</div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from '@vue/reactivity'
	import {
		onMounted,
		ref,
		toRefs
	} from 'vue'
	import {
		useRoute,
		useRouter
	} from 'vue-router'
	export default {
		name: 'footer',
		setup() {
			const router = useRouter()
			const data = reactive({
				logo: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/TransparentLogo.png', //透明logo
				list: [{
						name: '数字技术服务',
						arr: [{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykf.png',
								title: '数字应用开发',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyykfBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfn.png',
								title: '技术赋能',
								content: '随着互联网在全球范围内的扩展，中国互联网快速发展，',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jsfnBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjg.png',
								title: 'IT基础架构',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/jcjgBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szyg.png',
								title: '数字员工RPA',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/szygBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjs.png',
								title: '云计算',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/yjsBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyy.png',
								title: '大数据应用',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/dsjyyBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznyy.png',
								title: '人工智能应用',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/rgznBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qkl.png',
								title: '区块链',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/qklBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaq.png',
								title: '风控与安全',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/fkyaqBg.png',
							},
							{
								childName: '',
								child: [],
								image: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtyw.png',
								title: '系统运维',
								content: '全球联接和全球服务，IP免费用，真诚助力企业出海',
								bg: 'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/xtywBg.png',
							},
						]
					},
					{
						name: '咨询与解决方案',
						arr: [{
								childName: '咨询服务',
								child: [{
										image: '',
										title: '数字化转型咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字业务咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字产品咨询',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字创新创业咨询',
										content: '',
										bg: ''
									},
								]
							},
							{
								childName: '解决方案',
								child: [{
										image: '',
										title: '企业数字化基础套装',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字业务产品敏捷演进',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '业务与数据中台',
										content: '',
										bg: ''
									},
								]
							},
							{
								childName: '行业应用',
								child: [{
										image: '',
										title: '客户关系管理',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字渠道',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字营销',
										content: '',
										bg: ''
									},
									{
										image: '',
										title: '数字供应链',
										content: '',
										bg: ''
									}
								]
							}
						]
					},
					{
						name: '数字化运营',
						arr: [{
								childName: '',
								child: [],
								image: '',
								title: '数字内容服务',
								content: '',
								bg: ''
							},
							{
								childName: '',
								child: [],
								image: '',
								title: '业务流程服务',
								content: '',
								bg: ''
							},
							{
								childName: '',
								child: [],
								image: '',
								title: '客服服务',
								content: '',
								bg: ''
							}
						]
					}
				]
			})
			onMounted(() => {

			})
			const goDetail = (item,index) =>{
				// console.log(item)
				// router.push({
				// 	name: 'numberTechnology',
				// 	query: {
				// 		query: JSON.stringify(item)
				// 	}
				// })
			}
			return {
				...toRefs(data),
				goDetail,
			}
		}
	}
</script>

<style scoped>
	.itemssTitle {
		color: #999;
		margin-top: 10px;
	}
	
	.itemssTitle:hover{
		color: #BD00FF;
		cursor: pointer;
	}

	.wrap {
		background: #F2F2F2;
		width: 100%;
		height: 385px;
	}

	.wrapContainer {
		width: 70%;
		height: 255px;
		margin: 0 auto;
		padding-top: 48px;
		box-sizing: border-box;
		border-bottom: 1px solid #E1E1E1;
	}

	.bottom {
		height: 130px;
		width: 70%;
		font-size: 13px;
		color: #999999;
		margin: 0 auto;
	}
	
	.bottom a{
		color: #999;
	}

	.childWrap {
		width: 200px;
	}

	.childName {
		color: #999;
		margin-top: 10px;
		width: 85px;
	}
	
	.childName:hover{
		color: #BD00FF;
		cursor: pointer;
	}

	.ml35 {}

	.ml35:nth-child(2n+0) {
		margin-left: 30px;
	}

	.progress {
		width: 100%;
		/* height: 550px; */
		height: 28.6vw;
		background-image: url(https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/kjfn.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		/* margin-top: 131px; */
		text-align: center;
	}

	.progressTitle {
		font-size: 48px;
		padding-top: 11.9vw;
		box-sizing: border-box;
	}

	.progressBtn {
		width: 166px;
		height: 50px;
		background: #bd00ff;
		border-radius: 25px;
		box-shadow: 19.8px 19.8px 60px 28px rgba(2, 8, 22, 0.40);
		text-align: center;
		line-height: 50px;
		color: #fff;
		font-size: 16px;
		margin: 0 auto;
		margin-top: 36px;
		cursor: pointer;
	}
</style>
