import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import {
	encode,
	decode
} from './utils/base64.js'
const app = createApp(App)
app.config.globalProperties.$encode = encode; //单独的全局公共方法，跟vue2用prototype不同
app.config.globalProperties.$decode = decode; 
app
	.use(store)
	.use(router)
	.use(ElementPlus)
	.mount('#app')
