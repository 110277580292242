<template>
	<div class="swiperWrap">
		<!-- <div class="colorfff swiperTitle">科技赋能 共享进步</div> -->
		<el-carousel @change='swiperChange' indicator-position="none" :autoplay="true">
			<el-carousel-item v-for="(item,index) in bannerList" :key="index">
				<img :src="item" style="width: 100%;height: auto;">
			</el-carousel-item>
		</el-carousel>
		<div class="customIndicator flex align-center">
			<div v-for="(item,index) in bannerList" :key="index" class="customIndicatorChild"
				:style="swiperIndex==index?'background:#BD00FF':''"></div>
		</div>
	</div>
</template>

<script>
	import {
		reactive
	} from '@vue/reactivity'
	import {
		toRefs,
		onMounted
	} from 'vue'
	export default{
		name:'homeBanner',
		setup(){
			const data = reactive({
				bannerList: ['https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner.jpg',
					'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner1.jpg',
					'https://kootec.oss-cn-beijing.aliyuncs.com/officeWebsite/indexBanner2.jpg',
				],
				swiperIndex: 0,
			});
			// 轮播图位置
			const swiperChange = (e) => {
				// console.log(e)
				data.swiperIndex = e
			};
			return{
				...toRefs(data),
				swiperChange,
			}
		}
	}
</script>
<style type="text/css">
	.swiperWrap .el-carousel__container {
		height: 51vw;
	}
</style>
<style scoped>
	.swiperWrap {
		position: relative;
	}
	
	.swiperTitle {
		font-size: 60px;
		font-weight: bold;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 21.4vw;
		z-index: 666;
	}
	
	.customIndicatorChild {
		width: 12px;
		height: 12px;
		background: rgba(255, 255, 255, 0.5);
		border-radius: 50%;
		margin-right: 20px;
	}
	
	.customIndicator {
		position: absolute;
		transform: translate(-50%, -50%);
		bottom: 10%;
		left: 50%;
	}
</style>
