import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/numberTechnology',//二级菜单详情
    name: 'numberTechnology',
    component: () => import('../views/numberTechnology.vue')
  },
  {
    path: '/aboutUs',//关于我们
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
